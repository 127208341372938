// Main Palette
$primary: #bcd251;
$secondary: #A23436;
$background: #fbfbfb;
$light-background: #fff;

// Texts Palette
$font-title: #202020;
$font-primary: #535353;
$font-secondary: #787878;
$font-disabled: #c8c8c8;
$font-light: #fff;

// Other
$border-color: #a9a9a9;
$error: #e23437;